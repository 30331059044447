import http from "./http";
import Service from "../Service";

/**
 * @type ProviderService
 */
export default class ProviderService extends Service {
	
	find_by_phone(phone) {
		return http.get(`admin/providers/sellers/byphone`, {
			headers: this.getHeaders(),
            params: {
                phone
            }
		});
	}

	load(params) {
		return http.get(`admin/providers`, {
			headers: this.getHeaders(),
            params
		});
	}

    show(id) {
		return http.get(`admin/providers/${id}`, {
			headers: this.getHeaders()
		});
	}

    load_sellers(id, params) {
		return http.get(`admin/providers/${id}/sellers`, {
			headers: this.getHeaders(),
            params
		});
	}

    load_stores(id) {
		return http.get(`admin/providers/${id}/stores`, {
			headers: this.getHeaders()
		});
	}

    load_profiles(id) {
		return http.get(`admin/providers/${id}/profiles`, {
			headers: this.getHeaders()
		});
	}

    load_categories(id) {
		return http.get(`admin/providers/${id}/categories`, {
			headers: this.getHeaders()
		});
	}

	load_by_products(){
		return http.get(`admin/providers/load-by-products`, {
			headers: this.getHeaders()
		});
	}

    request_registrations(params) {
		return http.get(`admin/providers/registrations`, {
			headers: this.getHeaders(),
            params
		});
	}

    suspend_registration(id) {
		return http.delete(`admin/providers/registrations/${id}/suspend`, {
			headers: this.getHeaders()
		});
	}

    remove(id) {
		return http.delete(`admin/providers/${id}`, {
			headers: this.getHeaders()
		});
	}

    create(provider) {
        return http.post(`admin/providers`, provider, {
                headers: this.getHeaders()
            }
        );
    }

    update(provider) {
        return http.put(`admin/providers/${provider.id}`, provider, {
                headers: this.getHeaders()
            }
        );
    }

    upload_cover(id, cover) {
        const data = new FormData();
        data.append("cover", cover);
        return http.post(`admin/providers/${id}/cover`, data, {
            headers: this.getHeaders()
        });
    }
}
