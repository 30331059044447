<style lang="scss" scoped>
	@import "./new-provider.modal";
</style>
<template>
    <div class="row">
        <div class="col col-md-3 col-lg-3">
            <label>Nome:<span style="color:red !important">*</span></label>
            <ValidationProvider rules="required" v-slot="v">
                <cc-input type="text"
                    id="user-name"
                    v-model="provider.manager.name" />
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="col col-md-3 col-lg-3">
            <label>Login:<span style="color:red !important">*</span></label>
            <ValidationProvider rules="required" v-slot="v">
                <cc-input type="text"
                    id="user-login"
                    v-model="provider.manager.login" />
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="col col-md-3 col-lg-3">
            <label>CPF:<span style="color:red !important">*</span></label>
            <ValidationProvider rules="required" v-slot="v">
                <cc-input
                    v-mask="'###.###.###-###'"
                    type="text"
                    id="user-cpf"
                    v-model="provider.cpf" />
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="col col-md-3 col-lg-3">
            <label>Senha:<span style="color:red !important">*</span></label>
            <ValidationProvider rules="required" v-slot="v">
                <cc-input
                    type="password"
                    id="user-password"
                    v-model="provider.manager.password" />
                <span class="error">{{ v.errors[0] }}</span>
            </ValidationProvider>
        </div>
    </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
	mixins: [ loaderMixin ],
	props: {
		provider: {
			type: Object,
            required: true
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver
   	},
  	data() {
		return {

		};
	},
  	methods: {

	},
	mounted() {
	}
};
</script>
