<template>
    <div class="new-provider-body-separator">
        <div class="new-provider-body-text-title">Informações da matriz</div>
        <div class="new-provider-body-input-line">
            <div class="new-provider-body-info-checks">
                <StandardInput class="new-provider-body-input"  :warning="highlightAdress" :placeholder="provider.head_office.state ? provider.head_office.state.name || provider.head_office.state : ''" :title="'UF'" :action="setState" :type="'select'" :list="states"/>
                <StandardInput class="new-provider-body-input" :mask="'###.###-###'" :title="'CEP'" :auto="true" :value="provider.head_office.zip_code" :action="setZip" :type="'text'"/>
            </div>
            <StandardInput class="new-provider-body-input" :title="'Cidade'" :placeholder="provider.head_office.city ? provider.head_office.city.name || provider.head_office.city : ''" :warning="highlightAdress" :action="setCity" :type="'select'" :list="cities"/>
        </div>
        <div class="new-provider-body-input-line">
            <StandardInput class="new-provider-body-input" :title="'Endereço'" :auto="true" :action="setAdress" :value="provider.head_office.street" :type="'text'" />
            <StandardInput class="new-provider-body-input" :title="'Bairro'" :auto="true" :action="setDistruct" :value="provider.head_office.district" :type="'text'" />
        </div>
        <div class="new-provider-body-input-line">
            <StandardInput class="new-provider-body-input" :title="'Complemento'" :auto="true" :action="setComplement" :value="provider.head_office.complement" :type="'text'" />
        </div>
    </div>
</template>

<script>
import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'
import UserService from "@/services/v1/user.service";

export default {
    name: 'storeSection',
    mixins: [ loaderMixin ],
    props: [
        'provider',
        'highlightAdress',
        'states',
        'allCities'
    ],
    components: {
        ValidationProvider,
        ValidationObserver,
        StandardInput
    },
    data() {
        return {
            user_svc: new UserService(),
            cities: [],
        };
    },
    methods: {
        setState(value) { 
            this.provider.head_office.state = value
            this.provider.head_office.state.id = value.est_id
            this.cities = []
            this.allCities.forEach(element => {
                if (element.est_id == this.provider.head_office.state.id) this.cities.push({value: element, text: element.cid_nome})
            });
            this.$forceUpdate()
        },
        setCity(value) {
            this.provider.head_office.city = value
            this.provider.head_office.city.id = value.cid_id
        },
        setZip(value) {this.provider.head_office.zip_code = value},
        setAdress(value) {this.provider.head_office.street = value},
        setDistruct(value) {this.provider.head_office.district = value},
        setComplement(value) {this.provider.head_office.complement = value},

    },
}
</script>

<style lang="scss" scoped>@import "./new-provider.modalV2";</style>