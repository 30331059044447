<template>
    <div class="new-provider-body-separator">
        <div class="new-provider-body-text-title">Informações do usuário</div>
        <div class="new-provider-body-input-line">
            <StandardInput class="new-provider-body-input" :title="'Nome'" :action="setName" :value="provider.manager.name" :type="'text'" :auto="true"/>
            <StandardInput class="new-provider-body-input" :mask="'###.###.###-###'" :title="'CPF'" :value="provider.manager.cpf" :action="setCpf" :auto="true" :type="'text'"/>
        </div>
        <div class="new-provider-body-input-line">
            <StandardInput class="new-provider-body-input" :title="'Login '" :value="provider.manager.login" :action="setLogin" :type="'text'" :auto="true"/>
            <StandardInput class="new-provider-body-input" :password="true" :title="'Senha'" :value="provider.manager.password" :action="setPassword" :type="'text'" :auto="true"/>
        </div>
    </div>
</template>

<script>

import { loaderMixin } from '@/mixins/sweet-loader.mixin';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import StandardInput from '@/components/ui/inputs/StandardInputV2.vue'

export default {
	mixins: [ loaderMixin ],
	props: {
		provider: {
			type: Object,
            required: true
		}
	},
	components: {
		ValidationProvider,
		ValidationObserver,
        StandardInput
   	},
  	data() {
		return {

		};
	},
  	methods: {
        setName(value) { this.provider.manager.name = value },
        setCpf(value) { this.provider.manager.cpf = value},
        setLogin(value) { this.provider.manager.login = value},
        setPassword(value) { this.provider.manager.password = value},
	},
	mounted() {
	}
};
</script>

<style lang="scss" scoped>@import "./new-provider.modalV2";</style>